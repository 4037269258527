/**
 * Auto-submit form when users try to upload a file.
 */
function handleFileUpload() {
  const selector = document.querySelector('.product-key-upload');

  if (selector !== null) {
    selector.onchange = () => {
      selector.submit();
    };
  }
}

document.addEventListener('turbolinks:load', () => {
  handleFileUpload();
});
