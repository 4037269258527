require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

const feather = require('feather-icons');

import 'jquery';
import 'stylesheets/layout';

import './admin';

document.addEventListener('turbolinks:load', () => {
  feather.replace();
});
